<template>
    <div id="info" class="howto block pb-5">
        <div class="container block mt-4">
            <div class="block">
                <h3 class="title is-3">
                    QRokant?
                </h3>
            </div>
            <div class="intro px-4 block" >
                <p>
                    Jazeker! Maak van elk kadootje een digitale kindersurprise door er een QR-code met een persoonlijke boodschap op te plakken. Maak je kadootje QRokant!
                </p>       
            </div>
            <div class="block">
                <h3 class="title is-3">
                    Hoe werkt het?
                </h3>
            </div>

            <div class="block">
                Een QRokantje maken is zo gedaan!
            </div>
            <div class="intro px-4 block">
                <p>
                    QRokantjes zijn ‘mobile-first’: met je telefoon is het een fluitje van een cent. Maar ook op tablet of computer is een QRokantje zo gemaakt! Je plakt de QR-code van je QRokantje net als een foto van je telefoon zo op een kaart die je via Greetz (of andere dienst) verstuurt.
                </p>       
            </div>
        </div>
        <div class="columns is-multiline px-4 mx-0">
            <div class="column is-half-tablet is-one-quarter-desktop has-text-centered px-4">
                    <div class="icon-wrapper block">
                        <i class="far fa-hand-point-up howtoIcons"></i>
                    </div>
                    <h4 class="is-4 block">
                        KIES JE QROKANTJE
                    </h4>
                    <p class="block">
                        Bedenk wie je een QRokantje wilt sturen en kies er één uit.
                    </p>
            </div>
            <div class="column is-half-tablet is-one-quarter-desktop has-text-centered px-4">
                <div class="icon-wrapper block">
                    <i class="fas fa-pencil-alt howtoIcons"></i>
                </div>
                <h4 class="is-4 block">
                    PERSOONLIJKE TEKST
                </h4>
                <p class="block">
                    In elk QRokantje kan je wat persoonlijke woorden kwijt.
                </p>
            </div>
            <div class="column is-half-tablet is-one-quarter-desktop has-text-centered px-4">
                <div class="icon-wrapper block">
                    <i class="far fa-file-video howtoIcons"></i>
                </div>
                <h4 class="is-4 block">
                    MAAK EEN FILMPJE
                </h4>
                <p class="block">
                    Voeg een nieuw of bestaand filmpje toe aan jouw persoonlijke QRokantje.
                </p>
            </div>
            <div class="column is-half-tablet is-one-quarter-desktop has-text-centered px-4">
                <div class="icon-wrapper block">
                    <i class="fas fa-qrcode howtoIcons"></i>
                </div>
                <h4 class="is-4 block">
                    DOWNLOAD QROKANTJE
                </h4>
                <p class="block">
                    Plak je QRokantje in een Greetz-kaart, net zo makkelijk als een eigen foto op een kaart.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "QrokantInfo"
    }
</script>

<style>
    .howto {
        scroll-margin-top: 4em;
    }
    .howtoIcons {
        color: #03a9f4;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        font-size: 3rem;
        display: flex;
        border: 3px solid #03a9f4;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
    .icon-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
</style>
