import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		selectedTemplate: {
			name: null,
			image: null,
			input: {
				name: false,
				text: false,
				movie_file: false,
				picture_file: false,
				button_text: false
			},
			vue_app: false
		},
		codeId: null
	},
	mutations: {
		updateCodeId(state, payload) {
			state.codeId = payload
		}
	},
	actions: {
		setCodeId({ commit }, message) {
			commit('updateCodeId', message)
		}
	},
	getters: {
		getCodeId(state) {
			return state.codeId
		}
	}
})
