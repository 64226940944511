<template>
    <b-navbar id="navbar" type="is-dark" fixed-top>
        <template #brand>
            <b-navbar-item>
                <img src="@/assets/qrokant_logo_small_invert.png">
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item href="#home">
                Home
            </b-navbar-item>
            <b-navbar-item href="#info">
                Info
            </b-navbar-item>
            <b-navbar-item href="#qrokantjes">
                QRokantjes
            </b-navbar-item>
            <b-navbar-item href="#code">
                Code
            </b-navbar-item>
            <b-navbar-item href="#contact">
                Contact
            </b-navbar-item>
        </template>
    </b-navbar>  
</template>

<script>
    export default {
        name: "QrokantNavbar"
    }
</script>
