<template>
    <section class="hero is-dark is-fullheight-with-navbar pb-6">
        <div id="home" class="hero-body is-flex-direction-column is-justify-content-center">
            <div>
                <div class="block">
                    <img src="@/assets/qrokant_logo_small_invert.png">
                </div>
                <div class="block">
                    <h3 class="title is-size-3-tablet is-size-4-mobile">
                        De leukste verrassing van het land!
                    </h3>
                </div>
                <div class="block">
                    <b-button tag="a" href="#qrokantjes" type="is-light" outlined>
                        MAKEN!
                    </b-button>
                </div>
            </div>
        </div>
        <div class="hero-foot">
            <div class="bounce">
                <a href="#info">
                    <span class="icon is-large" href="#info">
                        <i class="fas fa-2x fa-angle-down"></i>
                    </span>
                </a>
            </div>
        </div>
    </section> 
</template>

<script>
    export default {
        name: "QrokantHome"
    }
</script>

<style scoped>
    #home {
        scroll-margin-top: 4em;
    }
    .hero-foot {
        height: 96px;
    }
    .bounce {
        animation-name: bounce;
        animation-timing-function: ease;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }
    @keyframes bounce {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-30px); }
        50%  { transform: translateY(0); }
        100% { transform: translateY(0); }
    }
</style>