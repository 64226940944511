<template>
    <div id="contact" class="block contact py-5">
        <div class="container block">
            <div class="block">
                <h3 class="title is-3">
                    Contact
                </h3>
            </div>
            <div class="intro px-4" >
                <p>
                    Heb je een vraag of een opmerking, mis je nog een QRokantje of wil je laten weten hoe jij je verrassing QRokant hebt gemaakt? Neem contact met ons op via het onderstaande formulier.
                </p>       
            </div>
            <div>
                <section class="section">
                    <b-field label="Naam">
                        <b-input v-model="feedback.name"></b-input>
                    </b-field>
                    <b-field label="Email">
                        <b-input v-model="feedback.email" type="email"
                            maxlength="40">
                        </b-input>
                    </b-field>
                    <b-field label="Bericht">
                        <b-input v-model="feedback.message" maxlength="600" type="textarea"></b-input>
                    </b-field>
                    <div class="buttons">
                        <b-button type="is-dark" :disabled="feedbackIsDisabled" expanded @click="sendFeedback">
                            Verstuur!
                        </b-button>
                    </div>
                </section>
            </div>
        </div>
    </div>   
</template>

<script>
    export default {
        name: "QrokantContact",

        data () {
            return {
                feedback: {
                    email: "",
                    name: "",
                    message: ""
                },
            }
        },

        computed: {
            feedbackIsDisabled() {
                return !this.feedback.name || !this.feedback.email || !this.feedback.message
            }           
        },

        methods: {
            sendFeedback() {
                fetch("https://bf0bww6uq1.execute-api.eu-central-1.amazonaws.com/api/feedback", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(this.feedback)
                })
                .then(response => response.json())
                .then(data => { 
                    this.$buefy.toast.open({
                        message: data.returnMessage,
                        type: 'is-dark',
                        duration: 4000,
                        position: 'is-bottom'
                    })
                    this.feedback.name = ''  
                    this.feedback.email = ''
                    this.feedback.message = ''
                })

            }           
        }
    }
</script>

<style>
    .contact {
        scroll-margin-top: 4em;
        background-color: rgb(245, 250, 253);
    }
</style>
