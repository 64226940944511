<template>
    <div id="code" class="block code">
        <div class="container block">
            <div class="block">
                <h3 class="title is-3">
                    Code
                </h3>
            </div>
            <div class="intro px-4" >
                <p>
                    Heb je een QRokantje met een code? Vul die hier in om je QRokantje te maken.
                </p>       
            </div>
            <div>
                <section class="section">
                    <b-field label="Code">
                        <b-input v-model="code.id"></b-input>
                    </b-field>
                    <div class="buttons">
                        <b-button type="is-dark" :disabled="codeIsDisabled" expanded @click="sendCode">
                            Check!
                        </b-button>
                    </div>
                </section>
            </div>
        </div>
    </div>   
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: "QrokantCode",

        data () {
            return {
                code: {
                    id: ""
                },
            }
        },

        computed: {
            codeIsDisabled() {
                return !this.code.id
            }           
        },

        methods: {
            ...mapActions(['setCodeId']),

            scrollToQRokantjes() {
                const section = document.getElementById('qrokantjes')
                section.scrollIntoView({ behavior: 'smooth' })
            },

            sendCode() {
                fetch(`https://si25t4obnf.execute-api.eu-central-1.amazonaws.com/api/check-id/${this.code.id}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                })
                .then(response => response.json())
                .then(data => { 
                    if (data.exists) {
                        console.log(data)
                        if ('template_id' in data['item']) {
                            this.$buefy.toast.open({
                                message: 'Sorry, dit QRokantje is al bezet. Probeer een andere code',
                                type: 'is-danger',
                                duration: 5000,
                                position: 'is-bottom'
                            })
                            this.code.id = null
                            this.setCodeId(null)                            
                        } else {
                            this.$buefy.toast.open({
                                message: 'Yes! We hebben de code gevonden, ga door met het maken van je QRokantje.',
                                type: 'is-success',
                                duration: 5000,
                                position: 'is-bottom'
                            })
                            this.setCodeId(this.code.id)
                            this.scrollToQRokantjes()
                        }
                    } else {
                        this.$buefy.toast.open({
                            message: 'Sorry, deze code hebben we niet kunnen vinden. Check of het de goede is.',
                            type: 'is-danger',
                            duration: 5000,
                            position: 'is-bottom'
                        })
                        this.code.id = null
                        this.setCodeId(null)
                    }
                })
            }      
        }
    }
</script>

<style scoped>
    .code {
        scroll-margin-top: 4em;
    }
</style>

