<template>
    <div id="app">
        <qrokant-navbar/>
        <qrokant-home/>
        <qrokant-info/>
        <div class="assortment block py-5" id="qrokantjes">
            <div class="container block">
                <div class="block">
                    <h3 class="title is-3">
                        QRokantjes om uit te kiezen
                    </h3>
                </div>
                <div class="intro px-4" >
                    <p>
                        Hieronder staan de eerste QRokantjes waar je uit kan kiezen. Er komen steeds meer QRokantjes bij. Het je een leuk idee dat er nog niet bij staat? Laat het ons weten! 
                    </p>       
                </div>
            </div>
            <div class="block">
                <h3 class="title is-4">
                    Movie Messages
                </h3>
            </div>
            <div class="intro px-4 pb-4" >
                <p>
                    Verras met een QRokant filmpje! Combineer deze QRokantjes met een filmpje voor een persoonlijke verrassing! 
                </p>       
            </div>
            <div class="columns mx-0 is-variable is-centered is-1-mobile is-mobile is-multiline">
                <div v-for="template in movie_messages" :key="template.name" class="column is-half-mobile is-one-third-tablet is-one-fifth-desktop" :id="template.template_id">
                    <div class="card" @click="selectMWAB(template)">
                        <div class="card-content">
                            <p class="title is-size-6-mobile is-size-5-tablet is-size-4-desktop">
                                {{template.name}}
                            </p>
                        </div>
                        <div class="card-image">
                            <figure class="image is-9by16">
                                <img :src="template.image" v-bind:alt="template.name">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <h3 class="title is-4">
                    QRokante puzzels
                </h3>
            </div>
            <div class="intro px-4 pb-4" >
                <p>
                    Een moeilijkere uitdaging? Maak geinige puzzels met een persoonlijke filmpje. Het filmpje kan bekeken worden als de puzzel is opgelost.
                </p>       
            </div>
            <div class="columns mx-0 is-variable is-centered is-1-mobile is-mobile is-multiline">
                <div v-for="template in puzzles" :key="template.name" class="column is-half-mobile is-one-third-tablet is-one-fifth-desktop" :id="template.template_id">
                    <div class="card" @click="selectMWAB(template)">
                        <div class="card-content">
                            <p class="title is-size-6-mobile is-size-5-tablet is-size-4-desktop">
                                {{template.name}}
                            </p>
                        </div>
                        <div class="card-image">
                            <figure class="image is-9by16">
                                <img :src="template.image" v-bind:alt="template.name">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <h3 class="title is-4">
                    AR Adventures
                </h3>
            </div>
            <div class="intro px-4 pb-4" >
                <p>
                    Stuur een avontuur! Met deze augmented reality QRokantjes kun een persoonlijke speurtocht maken. Zet de AR-figuren uit op de kaart en verras met een persoonlijk avontuur!
                </p>       
            </div>
            <div class="columns mx-0 is-variable is-centered is-1-mobile is-mobile is-multiline">
                <div v-for="template in ar_adventures" :key="template.name" class="column is-half-mobile is-one-third-tablet is-one-fifth-desktop" :id="template.template_id">
                    <div class="card" @click="selectMWAB(template)">
                        <div class="card-content">
                            <p class="title is-size-6-mobile is-size-5-tablet is-size-4-desktop">
                                {{template.name}}
                            </p>
                        </div>
                        <div class="card-image">
                            <figure class="image is-9by16">
                                <img :src="template.image" v-bind:alt="template.name">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <qrokant-code/>
        <qrokant-contact/>
        <b-modal 
            v-model="userInputModalActive" 
            has-modal-card
            trap-focus>
            <form action="">
                <div class="modal-card px-3">
                    <header class="modal-card-head">
                        <p class="modal-card-title"> {{selectedTemplate.name}}</p>
                        <button
                            type="button"
                            class="delete"
                            @click="closeInputModal"/>
                    </header>
                    <section v-if="user_input" class="modal-card-body">
                        <b-field v-if="!!selectedTemplate.ar_marker" label="AR-marker">
                            <div>
                                <p> 
                                    Voor dit avontuur heb je een augmented reality marker nodig:
                                </p>
                                <p>
                                    <img :src="'/markers/' + ar_marker_file" width="80px">
                                </p>
                                <p class="control">
                                    <b-button 
                                        tag="a"
                                        :href="'/markers/' + ar_marker_file"
                                        label="Download Marker" 
                                        type="is-primary"
                                        icon-left="cloud-download"
                                        download
                                        expanded>
                                    </b-button>
                                </p>
                            </div>
                        </b-field>

                        <b-field v-if="selectedTemplate.input.name" label="Naam">
                            <b-input
                                v-model="post_data.name"
                                placeholder="Voor wie is het?"
                                required>
                            </b-input>
                        </b-field>

                        <b-field v-if="selectedTemplate.input.text" label="Tekst">
                            <b-input
                                v-model="post_data.text"
                                type="textarea"
                                minlength="2"
                                maxlength="180"
                                placeholder="Verzin een geinige tekst"
                                required>
                            </b-input>
                        </b-field>

                        <b-field v-if="!!selectedTemplate.input.secret_code" label="Geheime code">
                            <b-input
                                v-model="post_data.secret_code"
                                placeholder="Wil je een geheime code toevoegen?">
                            </b-input>
                        </b-field>

                        <b-field v-if="selectedTemplate.input.picture_file" label="Foto">
                            <b-upload v-model="picture_file" expanded accept="image/jpeg" @input="selectPicture">
                                <a class="button is-fullwidth">
                                    <b-icon icon="upload"></b-icon>
                                    <span>{{ picture_file.name || "Kies je foto"}}</span>
                                </a>
                            </b-upload>
                        </b-field>

                        <b-field v-if="selectedTemplate.input.movie_file" label="Filmpje">
                            <b-upload v-model="movie_file" expanded accept="video/*" @input="selectMovie">
                                <a class="button is-fullwidth">
                                    <b-icon icon="upload"></b-icon>
                                    <span>{{ movie_file.name || "Kies je filmpje"}}</span>
                                </a>
                            </b-upload>
                        </b-field>

                        <b-field 
                            v-if="!!selectedTemplate.input.color_picker" 
                            grouped 
                            position="is-centered" 
                            label="Achtergrondkleur">   
                            <b-input v-model="post_data.color">
                            </b-input>
                            <p class="control">
                                <v-input-colorpicker v-model="post_data.color" /> 
                            </p>
                        </b-field>

                        <b-field 
                            v-if="!!selectedTemplate.input.letter_color" 
                            grouped 
                            position="is-centered" 
                            label="Letterkleur">   
                            <b-input v-model="post_data.letter_color">
                            </b-input>
                            <p class="control">
                                <v-input-colorpicker v-model="post_data.letter_color" /> 
                            </p>
                        </b-field>

                        <b-field
                            v-if="!!selectedTemplate.input.locations" 
                            label="Waar is de speurtocht?">
                            <b-input 
                                v-model="location_query"
                                placeholder="Plaats" 
                                type="search" 
                                expanded>
                            </b-input>
                            <p class="control">
                                <b-button 
                                    type="is-primary"
                                    @click="getLocationGeocode"
                                    icon-right="magnify">
                                </b-button>
                            </p>
                        </b-field>

                        <!-- <b-field 
                            v-if="!!selectedTemplate.input.locations" 
                            label="Locaties" 
                            grouped 
                            group-multiline>
                            <div v-for="location, key in selectedTemplate.input.locations" v-bind:key="location" style="width: 100%; position: relative">
                                {{locaties[key]}}

                                <div style="width: 100%; position: relative;">    
                                    <l-map 
                                        style="height: 300px; width: 100%"
                                        :ref="location"
                                        gestureHandling 
                                        :options="mapOptions"
                                        @ready="mapReady(location)"
                                        :zoom="zoom" 
                                        :center="center">
                                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                                        <div style="position: absolute; top: 0; left: 0; display: flex; align-items: center; justify-content: center; width:100%; height:100%; z-index: 9999">
                                            <img src="@/assets/location.png" width="20%"/>
                                        </div>
                                    </l-map>
                                </div>
                            </div>
                        </b-field> -->
                        <b-field
                            v-if="!!selectedTemplate.input.locations" 
                            :label="locations_text"
                            grouped 
                            group-multiline>
                            <div style="width: 100%; position: relative;">    
                                <l-map 
                                    style="height: 300px; width: 100%"
                                    ref="locations_map"
                                    gestureHandling 
                                    :options="mapOptions"
                                    @ready="mapReady"
                                    :zoom="zoom" 
                                    :center="center"
                                    @update:center="centerUpdated">
                                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                                    <v-locatecontrol/>
                                    <div style="position: absolute; top: 0; left: 0; display: flex; align-items: center; justify-content: center; width:100%; height:100%; z-index: 999">
                                        <img src="@/assets/location.png" width="20%"/>
                                    </div>
                                </l-map>
                            </div>

                            <div class="location_slider pt-3">
                                <b-button 
                                    type="is-primary"
                                    @click="removeLocation"
                                    :disabled = "locatie_counter == 0"
                                    icon-right="minus">
                                </b-button>
                                <div class="location_progress_container">
                                    <div class="location_progress">
                                        <b-progress
                                            :value="locatie_counter"
                                            :max="locaties.length"
                                            size="is-large"
                                            show-value
                                            :rounded="false">
                                            {{locatie_counter}} / {{locaties.length}}
                                        </b-progress>	
                                    </div>
                                </div>

                                <b-button 
                                    type="is-primary"
                                    @click="addLocation"
                                    :disabled = "locatie_counter == locaties.length"
                                    icon-right="plus">
                                </b-button>
                            </div>
                        </b-field>                               
                        <b-field v-if="selectedTemplate.input.button_text" label="Knopje">
                            <b-input
                                v-model="post_data.button_text"
                                placeholder="Tekst voor de startknop van het filmpje"
                                required
                                maxlength="12">
                            </b-input>
                        </b-field>
                    </section>
                    <section v-if="loading" class="modal-card-body">
                        Ff wachten, je QRokantje wordt gebouwd...
                        <figure>
                            <img src="./assets/building_qr.gif">
                        </figure>
                    </section>
                    <section v-if="show_qr" class="modal-card-body">
                        <div class="card-image">
                            <figure>
                                <img :src="qr_url" alt="qr_code">
                            </figure>
                        </div>
                        <div class="card-content">
                            <a :href="site_url" target="_blank">Check je QRokantje!</a>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Terug"
                            expanded
                            @click="closeInputModal" 
                            icon-left="chevron-left"
                            icon-pack="fa"/>
                        <b-button 
                            v-if="create_button"
                            label="Maak hem!"
                            expanded
                            :disabled="!ready_to_send"
                            @click="sendData"
                            type="is-dark"
                            icon-left="cogs"/>
                        <b-button
                            tag="a"
                            :href="qr_url"
                            type="is-dark"
                            v-if="loading || show_qr"
                            label="Download QR"
                            download
                            expanded
                            :disabled="!show_qr"
                            icon-left="cloud-download"
                            />
                    </footer>
                </div>
            </form>

        </b-modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { saveAs } from 'file-saver'
    import InputColorPicker from 'vue-native-color-picker'
    import QrokantNavbar from '@/components/QrokantNavbar.vue'
    import QrokantHome from '@/components/QrokantHome.vue'
    import QrokantInfo from '@/components/QrokantInfo.vue'
    import QrokantCode from '@/components/QrokantCode.vue'
    import QrokantContact from '@/components/QrokantContact.vue'
    import { latLng } from "leaflet"
    import icons from 'leaflet-color-number-markers'
    import { GestureHandling } from "leaflet-gesture-handling"
    import * as L from 'leaflet'
    import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    export default {
        name: 'App',

        components: {
            QrokantNavbar,
            QrokantHome,
            QrokantInfo,
            QrokantCode,
            QrokantContact,
            "v-input-colorpicker": InputColorPicker
        },

        data () {
            return {
                templates: [],
                userInputModalActive: false,
                greetzNotificationActive: true,
                selectedTemplate: {
                    name: null,
                    image: null,
                    input: {
                        name: false,
                        text: false,
                        movie_file: false,
                        picture_file: false,
                        button_text: false,
                        color: false
                    },
                    vue_app: false
                },
                ar_marker_url: "",
                ar_marker_file: "",
                site_url: "",
                qr_url: "",
                movie_file: {},
                picture_file: {},
                post_data: {
                    template_id: "",
                    name: "",
                    text: "",
                    button_text: "",
                    movie: false,
                    movie_name: "",
                    picture: false,
                    vue_app: false, 
                    color: "",
                    letter_color: "",
                    secret_code: "",
                    locations: {}
                },
                user_input: true,
                loading: false,
                location_query: "",
                show_qr: false,
                create_button: true,
                greetz_affiliate_url: "https://www.greetz.nl/kaarten?utm_source=awin&utm_medium=affiliate&utm_campaign=httpQRokantnl&utm_content=Contextual%20Targeting&referrer=awin&awc=9007_1616599475_df8b4d3e607fa197e4e98ef181f0398f",
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                zoom: 14,
                center: latLng(52.04463356680425, 6.10292684355845),
                centers: [],
                maps: {},
                locaties: [],
                markers: [],
                locatie_counter: 0,	
                location_map: null,
                mapOptions: {
                    gestureHandling: true,
                    zoomControl: false
                }
            }
        },

        methods: {
            getImgUrl(image) {
                return require(image)
            },

            selectMWAB(template_data) {
                this.userInputModalActive=!this.userInputModalActive
                this.selectedTemplate = template_data
                this.post_data.template_id = template_data.template_id
                this.post_data.vue_app = template_data.vue_app
                if ('color_picker' in template_data.input) {
                    this.post_data.color = template_data.input.color_picker
                }
                if ('letter_color' in template_data.input) {
                    this.post_data.letter_color = template_data.input.letter_color
                }
                if ('locations' in template_data.input) {
                    template_data.input.locations.forEach(
                        (location) => {
                            this.post_data[location] = {'lat': this.center.lat.toString(), 'lon': this.center.lng.toString()}
                        }
                    )
                    this.locaties = template_data.input.locations.map(x => x.replace('location', 'locatie '))
                    this.locatie_counter = 0
                }
                if ('ar_marker' in template_data) {
                    this.ar_marker_url = '@/assets/markers/' + template_data.ar_marker
                    this.ar_marker_file = template_data.ar_marker
                }
                if ('logo' in template_data) {
                    this.post_data['logo'] = template_data.logo
                }
            },

            checkFormFields(field) {
                if (field === 'movie_file' || field === 'picture_file') {
                    return this.post_data[field.split("_")[0]]
                } else {
                    return this.post_data[field] !== ""
                }
            },

            closeInputModal() {
                this.userInputModalActive=!this.userInputModalActive
                this.movie_file = {}
                this.picture_file= {}
                this.post_data = {
                    template_id: "",
                    name: "",
                    text: "",
                    button_text: "",
                    movie: false,
                    picture: false,
                    vue_app: false, 
                    secret_code: "",
                    color: "#00AE41"

                }
                this.loading = false
                this.show_qr = false
                this.user_input = true
                this.create_button = true
                this.locatie_counter = 0
            },

            // mapReady(location) {
            //     this.maps[location] = this.$refs[location][0].mapObject
            //     this.maps[location].on('moveend', () => {
            //         console.log(location)
            //         console.log(this.maps[location].getBounds().getCenter())
            //         this.post_data[location].lat = this.maps[location].getBounds().getCenter().lat.toString()
            //         this.post_data[location].lon = this.maps[location].getBounds().getCenter().lng.toString()
            //         console.log(this.post_data[location])
            //     })
            // },

            mapReady() {
                this.location_map = this.$refs.locations_map.mapObject
            },

            centerUpdated (center) {
                this.center = center;
            },
            
            addLocation() {
                this.locatie_counter += 1
                this.post_data['location' + this.locatie_counter] = {'lat': this.center.lat.toString(), 'lon': this.center.lng.toString()}
                let new_marker = L.marker(this.center, { icon: icons.red.numbers[this.locatie_counter] }).addTo(this.location_map)
                this.markers.push(new_marker)
            },

            removeLocation() {
                this.post_data['location' + this.locatie_counter] = null
                this.locatie_counter -= 1
                this.location_map.removeLayer(this.markers[this.locatie_counter])
                this.markers.splice(this.locatie_counter, 1)
            },

            getLocationGeocode() {
                let query = this.location_query.replace(' ', '%20')
                const base_url = "https://nominatim.openstreetmap.org/search";
                const params = new URLSearchParams({
                    city: query,
                    format: 'json',
                    limit: 1
                });

                const url = `${base_url}?${params.toString()}`;

                fetch(url)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data.length > 0) {
                            const result = data[0];
                            console.log(result);
                            this.center = latLng(result.lat, result.lon)
                            // Handle the result as needed
                        } else {
                            console.log("No results found.");
                        }
                    })
                    .catch(error => console.error("Error fetching data:", error));
            },

            selectMovie() {
                this.post_data.movie = true
            },

            selectPicture() {
                this.post_data.picture = true      
            },

            sendData() {
                this.loading = true
                this.user_input = false
                this.create_button = false
                this.post_data.movie_name = this.movie_file.name
                this.post_data.codeId = this.codeId
                fetch("https://osk8qhf5wi.execute-api.eu-central-1.amazonaws.com/api/", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(this.post_data)
                })
                .then(response => response.json())
                .then(data => {
                    if (data.presigned_url_movie) {
                        let psum = data.presigned_url_movie
                        let postData = new FormData()
                        for (let key in psum.fields) {
                            postData.append(key, psum.fields[key]);
                        }
                        postData.append('file', this.movie_file)     
                        fetch(psum.url, {
                            method: "POST",
                            body: postData
                        })
                        .then(() => {
                            console.log(data)
                            this.loading = false
                            this.show_qr = true  
                            // this.greetzAffiliate()
                        })
                        .catch(error => console.log(error))
                    }
                    if (data.presigned_url_picture) {
                        let psup = data.presigned_url_picture
                        let postData = new FormData()
                        for (let key in psup.fields) {
                            postData.append(key, psup.fields[key]);
                        }
                        postData.append('file', this.picture_file)     
                        fetch(psup.url, {
                            method: "POST",
                            body: postData
                        })
                        .then(() => {
                            if (!this.post_data.movie) {
                                this.loading = false
                                this.show_qr = true  
                                // this.greetzAffiliate()
                            }
                        })
                        .catch(error => console.log(error))
                    }
                    this.site_url = 'https://' + data.site_url
                    this.qr_url = data.qr_url.replace('https://mwab-app.s3.eu-central-1.amazonaws.com', '')
                })
                .then(() => {
                    if (!this.post_data.movie && !this.post_data.picture) {
                        this.loading = false
                        this.show_qr = true
                        // this.greetzAffiliate()
                    }
                })
            },

            downloadQr() {
                if (this.$browserDetect.isIOS) {
                    window.open(this.qr_url, '_blank')
                } else {
                    fetch(this.qr_url)
                    .then(response => response.blob())
                    .then(blob => {
                        saveAs(blob, 'QRokant.png')                    
                    })
                }
            },

            downloadArMarker() {
                if (this.$browserDetect.isIOS) {
                    window.open(this.ar_marker_url, '_blank')
                } else {
                    fetch(this.ar_marker_url)
                    .then(response => response.blob())
                    .then(blob => {
                        saveAs(blob, this.ar_marker_file)                    
                    })
                }
            },

            greetzAffiliate() {
                this.$buefy.dialog.confirm({
                    title: 'Super vet!',
                    message: 'Je QRokantje is klaar voor gebruik. Je kan ons sponsoren door een kaartje te bestellen bij Greetz. Kost jou niks extra, maar wij krijgen wat commissie van Greetz. 😊',
                    type: 'is-success',
                    cancelText: 'Meh...',
                    confirmText: 'Thanks! Ga ik doen!',
                    hasIcon: true,
                    icon: 'far fa-thumbs-up',
                    iconPack: 'fa',
                    onConfirm: () => window.open(this.greetz_affiliate_url, '_blank')
                })
            }
        },

        computed: {
            template_fields() {
                let obj = this.selectedTemplate.input
                // let result = Object.keys(obj).reduce((o, key) => {
                //     obj[key] == true && (o[key] = obj[key])
                //     return o
                // }, {})
                let result = Object.keys(obj).reduce((o, key) => {
                    if (key !== 'secret_code' && obj[key] === true) {
                        o[key] = obj[key];
                    }
                    return o;
                }, {});
                return result
            },

            ready_to_send() {
                if (this.locatie_counter == this.locaties.length & Object.keys(this.template_fields).every(this.checkFormFields)) {
                    return true
                } else {
                    return false
                }
            },

            locations_text() {
                if (this.locatie_counter < this.locaties.length) {
                    return 'Kies een plek voor ' + this.locaties[this.locatie_counter]
                } else {
                    return 'Alle locaties zijn gekozen!'
                }
            },

            movie_messages() {
                return this.templates.filter(template => template.category == 'movie_message')
            },

            ar_adventures() {
                return this.templates.filter(template => template.category == 'ar_adventure')
            },

            puzzles() {
                return this.templates.filter(template => template.category == 'puzzle')
            },

            ...mapGetters(['getCodeId']),
            codeId() {
                return this.getCodeId
            }
        },

        created() {
            fetch("https://m33w3smpr0.execute-api.eu-central-1.amazonaws.com/api/")
                .then(response => response.json())
                .then(data => (this.templates = data));
        }
    }
</script>

<style>
    @import "~leaflet/dist/leaflet.css";
    @import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

    #app {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #363636;
    }

    html {
        scroll-behavior: smooth;
    }
    body {
        background-color: rgb(255, 255, 255);
    }
    .intro {
        max-width: 994px;
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .assortment {
        background-color: rgb(245, 250, 253);
        scroll-margin-top: 3em;
    }
    .control .icp__input[data-v-512dc202] {
        height: 40px; 
    }
    .location_slider {
        display: flex;
        width: 100%;
        gap: 12px;
    }
    .location_progress_container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .location_progress {
        width: 100%;
    }
</style>
